import { Col, Container, Row } from "react-bootstrap";
import React from "react";
import { useLocation } from "react-router-dom";
import { ImportAnimalCard } from "./ImportAnimalCard";
import { Header } from "./components/Header";
import { ImportAnimalCardRu } from "./ImportAnimalCardRu";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function ImportAnimalRu() {
  let query = useQuery();
  // @ts-ignore
  const data = query.get("data");

  console.log("params: " + data);

  return (
    <Container fluid>
      <Header />

      <Row>
        <Col
          sm={12}
          md={6}
          lg={5}
          className={"body"}
          style={{
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <ImportAnimalCardRu data={data} />
        </Col>
      </Row>
      <Row xs="auto" className={"padding"} />

      <Row>
        <h6 style={{ textAlign: "center", color: "white" }}>
          {" "}
          Для импорта животного необходимо скачать приложение
        </h6>
      </Row>
      <Row xs="auto" className={"padding"} />

      <Row>
        <Col xs={"auto"} className={"links"}>
          <a
            href="https://apps.apple.com/ru/app/reptile-rocket-pet-tracker/id1558082005?itsct=apps_box_badge&amp;itscg=30200"
            style={{
              display: "inline-block",
              overflow: "hidden",
              borderRadius: "13px",
              width: "250px",
              height: "83px",
            }}
          >
            <img
              src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1644019200&h=08f283e45fabaf3db97edf5219f810dd"
              alt="Download on the App Store"
              style={{ borderRadius: "13px", width: "250px", height: "83px" }}
            />
          </a>
        </Col>
      </Row>

      <Row xs="auto" className={"padding"} />

      <Row>
        <Col xs={"auto"} className={"links"}>
          <a href="https://play.google.com/store/apps/details?id=com.zorbsoft.reptile_rocket&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <img
              alt="Get it on Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              style={{ width: "285px" }}
            />
          </a>
        </Col>
      </Row>

      <Row xs="auto" className={"padding"} />
      <Row>
        <Col xs={"auto"} className={"links"}>
          Email:&nbsp;
          <a href="mailto:rreptileapp@gmail.com">rreptileapp@gmail.com</a>
        </Col>
      </Row>
      <Row xs="auto" className={"padding"} />
    </Container>
  );
}
