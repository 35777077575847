import { Col, Container, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { Header } from "./components/Header";

import img1 from "./assets/images/logo1.png";
import img2 from "./assets/images/logo2.png";

export const MainPage = () => {
  const [isMobile, setIsMobile] = useState(false);

  //choose the screen size
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // create an event listener
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  console.log(isMobile);

  return (
    <Container fluid>
      <Header />

      <Row>
        <Col style={{ textAlign: "center" }}>
          <h2> Reptile Rocket: smart pet tracker</h2>
            Reptile tracker and planner with QR scanner
          <br />
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: "center" }} xl={2} />

        <Col style={{ textAlign: "center" }} xl={4}>
          <img
            src={img1}
            style={{ width: isMobile ? "20rem" : "25rem" }}
            alt={""}
          />
        </Col>

        <Col style={{ textAlign: "center" }} xl={4}>
          <img
            src={img2}
            style={{ width: isMobile ? "20rem" : "25rem" }}
            alt={""}
          />
        </Col>

        <Col style={{ textAlign: "center" }} xl={2} />
      </Row>

      <Row xs="auto" className={"padding"} />

      <Row>
        <Col xs={"auto"} className={"links"}>
          <a
            href="https://apps.apple.com/ru/app/reptile-rocket-pet-tracker/id1558082005?itsct=apps_box_badge&amp;itscg=30200"
            style={{
              display: "inline-block",
              overflow: "hidden",
              borderRadius: "13px",
              width: "250px",
              height: "83px",
            }}
          >
            <img
              src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1644019200&h=08f283e45fabaf3db97edf5219f810dd"
              alt="Download on the App Store"
              style={{ borderRadius: "13px", width: "250px", height: "83px" }}
            />
          </a>
        </Col>
      </Row>

      <Row xs="auto" className={"padding"} />

      <Row>
        <Col xs={"auto"} className={"links"}>
          <a href="https://play.google.com/store/apps/details?id=com.zorbsoft.reptile_rocket&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <img
              alt="Get it on Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              style={{ width: "285px" }}
            />
          </a>
        </Col>
      </Row>

      <Row xs="auto" className={"padding"} />

      <Row>
        <Col xs={"auto"} className={"links"}>
          Email:&nbsp;
          <a href="mailto:rreptileapp@gmail.com">rreptileapp@gmail.com</a>
        </Col>
      </Row>
      <Row xs="auto" className={"padding"} />
    </Container>
  );
};
