import { Button, Card } from "react-bootstrap";
import React, { FC } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import { Base64 } from "js-base64";
import moment from "moment";

export const ImportAnimalCard: FC<any> = ({ data }) => {
  let animal = JSON.parse(Base64.decode(data));

  let importAnimal = () => {
    if (isAndroid) {
      // @ts-ignore
      window.location =
        "intent://reptile/" +
        data +
        "#Intent;package=com.zorbsoft.reptile_rocket;scheme=reptile;end";
    } else if (isIOS) {
      // @ts-ignore
      window.location = "reptile://" + data;
    } else {
      alert("Import is only available on mobile platforms");
    }
  };

  return (
    <Card className={"animal"} text={"white"} body={true}>
      <Card.Body>
        <Card.Title>You got an animal</Card.Title>
        <Card.Text>
          {animal.animalName !== null ? (
            <>
              {"Name: " + animal.animalName}
              <br />
            </>
          ) : (
            ""
          )}
          {animal.gender !== null ? (
            <>
              {"Sex: " + animal.gender}
              <br />
            </>
          ) : (
            ""
          )}
          {animal.hatchDate !== null ? (
            <>
              {"Hatching date: " + moment(animal.hatchDate).format("L")}
              <br />
            </>
          ) : (
            ""
          )}
          {animal.animalSpecies !== null ? (
            <>
              {"Species: " + animal.animalSpecies}
              <br />
            </>
          ) : (
            ""
          )}
          {animal.animalMorph !== null ? (
            <>
              {"Morph: " + animal.animalMorph}
              <br />
            </>
          ) : (
            ""
          )}
          {animal.dateOfEating !== null ? (
            <>
              {"Feeding date: " +
                moment(animal.dateOfEating).format("L") +
                " " +
                moment(animal.dateOfEating).format("LT")}
              <br />
            </>
          ) : (
            ""
          )}
          {animal.dateOfMolt !== null ? (
            <>
              {"Shedding date: " + moment(animal.dateOfMolt).format("L")}
              <br />
            </>
          ) : (
            ""
          )}
          {animal.size !== null ? (
            <>
              {"Size: " +
                animal.size +
                " " +
                (animal.sizeTypeLabel) +
                " Date of measurement: " +
                moment(animal.dateOfSize).format("L")}
              <br />
            </>
          ) : (
            ""
          )}
        </Card.Text>
        <Button
          variant="primary"
          onClick={() => {
            importAnimal();
          }}
        >
          Import
        </Button>
      </Card.Body>
    </Card>
  );
};
