import { Col, Row } from "react-bootstrap";
import React from "react";

export const Header = () => {
  return (
    <>
      <Row xs="auto" className={"header1"} />

      <Row xs="auto" className={"header2"}>
        <Col style={{ padding: "0px  0px  0px 12px" }}>
          <img
            src={window.location.origin + "/logo.png"}
            style={{ height: "2rem" }}
            alt={"Logo"}
          />
        </Col>
        <Col className={"appName"} style={{ padding: "0px  0px  0px 0px" }}>
          <h1>eptile Rocket</h1>
        </Col>
      </Row>

      <Row xs="auto" className={"header11"} />
      <Row xs="auto" className={"padding"} />
    </>
  );
};
